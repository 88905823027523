<template>
    <div>
        <v-card :loading="loading">
            <v-card-title class="text-h5">
                <v-btn
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="closeDialog"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                Modification Request
                <v-spacer />
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" v-if="quoteForm">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="quote.name"
                                :rules="[rules.required]"
                                prepend-icon="mdi-file-document-edit"
                                label="Name *"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="quote.userId"
                                :items="users"
                                item-text="name"
                                item-value="id"
                                prepend-icon="mdi-account"
                                label="Account Manager *"
                                required
                                :rules="[rules.required]"
                            >
                                <template slot="item" slot-scope="{ item }">
                                    <v-row>
                                        <p class="ma-0 ml-1 pa-0">
                                            {{ item.name }}
                                        </p>
                                        <h5
                                            class="grey--text ma-0 ml-1 mt-1 pa-0"
                                        >
                                            ({{ item.role }})
                                        </h5>
                                    </v-row>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="quote.preconManagerId"
                                :items="users"
                                item-text="name"
                                item-value="id"
                                prepend-icon="mdi-account"
                                label="Precon Manager"
                            >
                                <template slot="item" slot-scope="{ item }">
                                    <v-row>
                                        <p class="ma-0 ml-1 pa-0">
                                            {{ item.name }}
                                        </p>
                                        <h5
                                            class="grey--text ma-0 ml-1 mt-1 pa-0"
                                        >
                                            ({{ item.role }})
                                        </h5>
                                    </v-row>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="quote.estimatorId"
                                :items="users"
                                item-text="name"
                                item-value="id"
                                prepend-icon="mdi-account"
                                label="Estimator *"
                                required
                                :rules="[rules.required]"
                            >
                                <template slot="item" slot-scope="{ item }">
                                    <v-row>
                                        <p class="ma-0 ml-1 pa-0">
                                            {{ item.name }}
                                        </p>
                                        <h5
                                            class="grey--text ma-0 ml-1 mt-1 pa-0"
                                        >
                                            ({{ item.role }})
                                        </h5>
                                    </v-row>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="quote.clientId"
                                :loading="loading"
                                :items="filterClients"
                                item-text="name"
                                item-value="id"
                                @change="selectClient"
                                prepend-icon="mdi-office-building"
                                label="Client *"
                                required
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                multiple
                                v-model="quote.contacts"
                                @change="deleteSearch"
                                :search-input.sync="searchContacts"
                                :loading="loading"
                                :items="clientContacts"
                                item-text="name"
                                item-value="id"
                                prepend-icon="mdi-account-tie"
                                label="Client contact "
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="quote.country"
                                :items="this.countries"
                                prepend-icon="mdi-earth"
                                label="Country"
                                @change="selectState"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="quote.state"
                                @change="selectCity"
                                :items="this.states"
                                prepend-icon="mdi-map-marker-radius"
                                label="State"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-combobox
                                v-model="quote.city"
                                :filter="filter"
                                :hide-no-data="!search"
                                :items="this.cities"
                                hide-details
                                prepend-icon="mdi-city"
                                :search-input.sync="search"
                                hide-selected
                                label="City"
                                flat
                                @focus="enabled = false"
                                @blur="enabled = true"
                            >
                                <template v-slot:item="{ index, item }">
                                    <v-text-field
                                        v-if="editing === item"
                                        v-model="editing.text"
                                        autofocus
                                        flat
                                        background-color="transparent"
                                        hide-details
                                        solo
                                        @keyup.enter="edit(index, item)"
                                    ></v-text-field>
                                    <span v-else>
                                        {{ item.text }}
                                    </span>
                                    <v-spacer></v-spacer>
                                </template>
                            </v-combobox>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                v-model="menu2"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="quote.invitationDate"
                                        label="Invitation Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details
                                        class="pt-3"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="quote.invitationDate"
                                    @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row class="mt-8">
                        <v-col cols="6">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                v-model="menu"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="quote.requestDate"
                                        label="Due Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details
                                        class="pt-0"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="quote.requestDate"
                                    @input="menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                v-model="menu3"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="quote.promiseDate"
                                        label="Promise Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details
                                        class="pt-0"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="quote.promiseDate"
                                    @input="menu3 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-combobox
                                v-model="quote.tags"
                                :filter="filter"
                                :hide-no-data="!searchTags"
                                :items="tags"
                                hide-details
                                :search-input.sync="searchTags"
                                hide-selected
                                multiple
                                small-chips
                                flat
                                label="tags"
                                prepend-icon="mdi-tag-multiple"
                            >
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-chip color="grey lighten-3" small>
                                            {{ searchTags }}
                                        </v-chip>
                                    </v-list-item>
                                </template>
                                <template
                                    v-slot:selection="{
                                        attrs,
                                        item,
                                        parent,
                                        selected,
                                    }"
                                >
                                    <v-chip
                                        v-if="item === Object(item)"
                                        v-bind="attrs"
                                        color="grey lighten-3"
                                        :input-value="selected"
                                        small
                                    >
                                        <span class="pr-2">
                                            {{ item.text }}
                                        </span>
                                        <v-icon
                                            small
                                            @click="parent.selectItem(item)"
                                        >
                                            mdi-close-circle
                                        </v-icon>
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ index, item }">
                                    <v-text-field
                                        v-if="editing === item"
                                        v-model="editing.text"
                                        autofocus
                                        flat
                                        background-color="transparent"
                                        hide-details
                                        solo
                                        @keyup.enter="edit(index, item)"
                                    ></v-text-field>
                                    <v-chip v-else color="grey lighten-3" small>
                                        {{ item.text }}
                                    </v-chip>
                                    <v-spacer></v-spacer>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="quote.notes"
                                prepend-icon="mdi-contacts"
                                label="Notes"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="mb-4" no-gutters>
                        <v-col cols="auto" class="d-flex align-center">
                            <span>Bid Documents</span>
                        </v-col>
                        <v-col class="d-flex align-center flex-wrap">
                            <v-btn
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1  "
                                v-for="(file, i) in quote.files"
                                :key="i"
                            >
                                <span @click="openFile(file)">{{
                                    file.name
                                }}</span>
                                <v-icon
                                    @click="deleteFile(file)"
                                    class="ml-1"
                                    small
                                >
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                depressed
                                fab
                                x-small
                                color="primary"
                                class="ml-2 position-relative overflow-hidden"
                            >
                                <v-icon>mdi-plus</v-icon>
                                <input
                                    type="file"
                                    multiple
                                    class="file-input"
                                    @change="onFilesPicked"
                                />
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <div v-else>
                    Write the reason for the modification request.
                    <v-form ref="formReason" class="mt-2">
                        <v-textarea
                            label="Reasons *"
                            v-model="modificationMessage"
                            hide-details
                            :rules="[rules.required]"
                            required
                            rows="1"
                        ></v-textarea>
                    </v-form>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    v-if="!quoteForm"
                    text
                    color="primary"
                    @click="quoteForm = true"
                >
                    Prev
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    :loading="loading2"
                    @click="saveUpdates"
                    :disabled="!validateConditions()"
                >
                    {{ quoteForm ? 'Next' : 'Save' }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--contacts warning-->
        <v-dialog
            v-model="contactsWarning"
            persistent
            max-width="500px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="contactsWarning">
                <v-card-title class="text-h5"
                    >No contacts selected</v-card-title
                >
                <v-card-text>
                    No contacts selected, the client cannot be notified. do you
                    want to continue?
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-btn
                        @click="contactsWarning = false"
                        color="error"
                        :loading="loading"
                        rounded
                    >
                        NO
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="saveUpdates"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        YES
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
const countrycitystate = require('countrycitystatejson')
import { objDiff } from '@/helpers/objDiff'
import { storage } from '@/services/firebase'
import { openFile } from '@/services/storage/openFile'

export default {
    name: 'QuoteForm',
    props: {
        originalQuote: {
            type: Object,
        },
        settings: Array,
        loading2: Boolean,
    },
    data() {
        return {
            clientId: null,
            user: JSON.parse(localStorage.getItem('userId')),
            company: JSON.parse(localStorage.getItem('company')),
            loading: false,
            error: false,
            clientContacts: [],
            errorMsg: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
            },
            listCountries: null,
            countries: [],
            cities: [],
            states: [],
            shortNameCity: null,
            editing: null,
            search: null,
            searchTags: null,
            tags: [],
            model: [],
            users: [],
            requestDate: '',
            invitationDate: '',
            promiseDate: '',
            menu: false,
            menu2: false,
            menu3: false,
            quote: {},
            clients: [],
            enabled: true,
            searchContacts: null,
            quoteForm: true,
            modificationMessage: '',
            contactsWarning: false,
            folderQuotes: 'quotes_documents',
            attach: [],
            filesToDelete: [],
        }
    },
    watch: {
        model(val, prev) {
            if (val.length === prev.length) return
            this.searchTags = null
            this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        text: v,
                    }
                    this.tags.push(v)
                    this.error = false
                }
                return v
            })
        },
    },
    computed: {
        quoteDiff: function() {
            return objDiff(this.originalQuote, this.quote)
        },
        filterClients: function() {
            return this.clients.filter(client => client.enabled === true)
        },
    },
    async mounted() {
        try {
            let companyInfo = this.settings.find(s => s.name === 'Company')
            this.tags = companyInfo.tags?.map(t => {
                return {
                    text: t,
                    value: t,
                }
            })

            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users

            if (this.originalQuote.requestDate) {
                this.originalQuote.requestDate = this.formatDate(
                    this.originalQuote.requestDate._seconds
                )
            }

            if (this.originalQuote.invitationDate) {
                this.originalQuote.invitationDate = this.formatDate(
                    this.originalQuote.invitationDate._seconds
                )
            }

            if (this.originalQuote.promiseDate) {
                this.originalQuote.promiseDate = this.formatDate(
                    this.originalQuote.promiseDate._seconds
                )
            }

            if (this.originalQuote?.files?.length > 0) {
                this.quote.files = this.originalQuote.files
            }

            this.quote = _.cloneDeep(this.originalQuote)
            this.quote.requestDate = null
            this.quote.invitationDate = null
            this.quote.promiseDate = null

            await this.getClients()
            await this.selectClient(this.quote.clientId)
            this.listCountries = countrycitystate.getCountries()
            this.countries = this.listCountries.map(x => x.name)

            if (this.quote.country) {
                this.selectState(this.quote.country)
            }

            if (this.quote.state) {
                this.selectCity(this.quote.state)
            }

            this.quote.tags = this.quote.tags?.map(t => {
                return {
                    text: t,
                    value: t,
                }
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions([
            'addUsersAction',
            'addClientsAction',
            'addContactsAction',
        ]),
        closeDialog: function() {
            this.contactsWarning = false
            this.error = false
            this.errorMsg = null
            this.$emit('closeDialog')
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        saveLead: function() {
            this.updateLead()
        },
        selectClient: function(event) {
            this.clientId = event
            this.getContactsByClient()
        },
        async getContactsByClient() {
            try {
                this.loading = true
                const {
                    data: { contacts },
                } = await API.getContactsByClient({
                    clientId: this.clientId,
                })
                this.clientContacts = contacts
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveUpdates() {
            try {
                this.loading = true
                if (this.quoteForm) {
                    if (
                        (!this.quote.contacts ||
                            this.quote.contacts.length == 0) &&
                        !this.contactsWarning
                    ) {
                        this.contactsWarning = true
                    } else {
                        this.contactsWarning = false
                        this.quote.accountManager = this.getUser(
                            this.quote.userId
                        )
                        this.quote.clientName = this.clients.filter(
                            client => client.id == this.quote.clientId
                        )[0].name
                        if (this.quote.tags != null) {
                            let tags2 = []
                            this.quote.tags.forEach(t => {
                                tags2.push(t.text)
                            })
                            this.quote.tags = tags2
                        }
                        if (
                            this.quote.preconManagerId &&
                            this.originalQuote.preconManagerId
                        ) {
                            if (
                                this.quote.preconManagerId !=
                                this.originalQuote.preconManagerId
                            ) {
                                this.quote.collaborators.push(
                                    this.quote.preconManagerId
                                )
                                this.quote.collaborators = this.quote.collaborators.filter(
                                    q => q != this.originalQuote.preconManagerId
                                )
                            }
                        }
                        if (this.quote.userId != this.originalQuote.userId) {
                            this.quote.collaborators.push(this.quote.userId)
                            this.quote.collaborators = this.quote.collaborators.filter(
                                u => u != this.originalQuote.userId
                            )
                        }
                        if (this.quoteDiff.tags) {
                            this.quoteDiff.tags = this.quote.tags
                        }

                        delete this.quoteDiff.accountManager
                        delete this.quoteDiff.clientName

                        this.quoteForm = false
                    }
                } else {
                    // create files in storage
                    if (this.quote.files.length > 0) {
                        const path = `${this.company}/${this.folderQuotes}/${this.quote.id}`
                        const storageRef = storage().ref(path)

                        // clear files data
                        for (let file of this.quote.files) {
                            if (file.blob) {
                                delete file.blob
                            }
                        }

                        if (this.filesToDelete.length > 0) {
                            const storageRef = storage().ref(path)
                            for (let file of this.filesToDelete) {
                                const docRef = storageRef.child(file.file)
                                await docRef.delete()
                            }
                            this.filesToDelete = []
                        }

                        for (let i = 0; i < this.quote.files.length; i++) {
                            if (this.quote.files[i].recentlyAdded) {
                                const leadDocRef = storageRef.child(
                                    this.quote.files[i].name
                                )

                                var metadata = {
                                    customMetadata: {
                                        filename: this.quote.files[i].name,
                                    },
                                }
                                await leadDocRef.put(
                                    this.quote.files[i].fileData,
                                    metadata
                                )
                            }
                            delete this.quote.files[i].recentlyAdded
                        }
                    }
                    this.$emit('modificationRequest', {
                        message: this.modificationMessage,
                        quoteUpdates: this.quoteDiff,
                        files: this.quote.files || [],
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getUser(id) {
            const user = this.users.find(u => u.id == id)
            if (user) {
                return user.name
            }
            return null
        },
        getClients: async function() {
            try {
                this.loading = true
                this.loadingError = false
                this.errorMsg = null
                const {
                    data: { clients },
                } = await API.getClients({ companyId: this.company })
                this.clients = clients
                this.addClientsAction(clients)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x.name === event)
            this.shortNameCity = this.listCountries[index].shortName
            this.states = countrycitystate.getStatesByShort(this.shortNameCity)
        },
        selectCity: function(event) {
            this.cities[0] = { header: 'Select or create one' }
            let data = countrycitystate.getCities(this.shortNameCity, event)
            if (data.length == 0) {
                this.cities = []
            }
            data.forEach(city => {
                this.cities.push({ text: city })
            })
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        deleteSearch() {
            this.searchContacts = ''
        },
        validateConditions() {
            if (this.quoteForm) {
                return this.valid
            } else {
                return this.modificationMessage.length > 0
            }
        },
        onFilesPicked(event) {
            const selectedFiles = Array.from(event.target.files)

            selectedFiles.forEach(element => {
                const docs = {
                    name: element.name,
                    file: element.name,
                    attach: false,
                    recentlyAdded: true,
                    blob: URL.createObjectURL(element),
                    fileData: element,
                }

                if (!this.quote.files) {
                    this.$set(this.quote, 'files', [])
                }

                this.quote.files.push(docs)
            })
            event.target.value = ''
        },
        async openFile(file) {
            try {
                if (!file.recentlyAdded) {
                    this.loading = true
                    this.errMessage = ''
                    const path = `${this.company}/${this.folderQuotes}/${this.quote.id}`
                    await openFile(file.file, path)
                } else {
                    window.open(file.blob, '_blank')
                }
            } catch (error) {
                this.errMessage = error.message
                this.$emit('viewError', this.errMessage)
            } finally {
                this.loading = false
            }
        },
        async deleteFile(file) {
            try {
                const index = this.quote.files.findIndex(
                    quoteFile => quoteFile.file === file.file
                )

                if (index !== -1) {
                    this.quote.files.splice(index, 1)
                }

                if (!file.recentlyAdded) {
                    this.filesToDelete.push(file)
                }
            } catch (error) {
                console.error(`Failed to delete files in path: `, error)
            }
        },
    },
}
</script>

<style>
.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
</style>
